
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import useTranslation from "next-translate/useTranslation";
import Link from "next/link";
import { Col, Row } from "reactstrap";
import BaseLayout from "@components/BaseLayout";
import RSSFeedToEventsView from "@components/common/events/RSSFeedToEventsView";
import { BASE_URL } from "config";
/**
 * Cottbus-spezifische Startseite
 */
const FrontPage: React.FC = () => {
    const { t } = useTranslation("common");
    // const customerPreview: boolean = useSelector((state: AppState) =>
    //   state.applicationStates.settings.customerPreview
    // )
    // use window size for resizing the embedded video iframe element
    // const windowSize = useWindowSize()
    /**
     * calculates dimensions based on given dimensions, adapted to the the current window-dimensions
     *
     * @param defaultMaxWidth a default width, that functions as maximum width value
     * @param defaultMaxHeight a default hight, that functions as maximum height value
     * @returns calculated dimensions with same proportion then default values, but adapted to current window dimensions
     *
     * @todo recalculate on height resizing too
     */
    // const calculateProportionateDimensionsOnBrowserDimensions = (defaultMaxWidth: number, defaultMaxHeight: number): { width: number; height: number } => {
    //   if (typeof window === undefined) {
    //     // not running in browser? return default values
    //     return { width: defaultMaxWidth, height: defaultMaxHeight }
    //   } else {
    //     // when running in browser use innerWidth and innerHeight to calculate smaller dimension
    //     let width = defaultMaxWidth
    //     let height = defaultMaxHeight
    //     if (windowSize.width > defaultMaxWidth) {
    //       width = defaultMaxWidth
    //       height = defaultMaxHeight
    //     }
    //     else {
    //       width = windowSize.width
    //       height = defaultMaxHeight * width / defaultMaxWidth
    //     }
    //     return { width, height }
    //   }
    // }
    return <BaseLayout pageTitle={t("page.index.title")} isFrontPage={true}>
    <div className="frontpage-content">
      <section>
        <Row className="no-flex text-center">
          <h1>
            IdeenLAB
          </h1>
          <h2 className="light">
            Gestalte Dein Cottbus!
          </h2>
          <h3 className="margin-top">
            Willkommen auf der Beteiligungsplattform der Digitalen Stadt Cottbus/Chóśebuz für den
            Bildungsbereich! Reiche ganz einfach Ideen ein, entwickle gemeinsam mit anderen Projekte und
            gestalte so die digitale Bildung in unserer Stadt.
          </h3>
          <br />
          <Link href="/ideas/create"><a className="btn">Idee einreichen</a></Link>
        </Row>
      </section>

      <section className="on-white ">
        <>
          <Row className="flex">
            <Col className="text-center">
              <h2>Deine Idee, Dein Projekt, Dein Cottbus!</h2>
            </Col>
          </Row>
          <Row>
            <Col className="col-big-breakpoint">
              <p>
                Du möchtest Dich für digitale Bildung engagieren und an der Lösung aktueller Anliegen der Bürger:innen von Cottbus mitwirken? Du hast eine Idee, die Du in einem konkreten Projekt umsetzen möchtest? Dann lass Dich vom IdeenLAB dabei unterstützen!
              </p>
              <p>
                Hier kannst Du Deine Idee von der Vision bis zur Umsetzung als Projekt planen und organisieren. Dich mit anderen vernetzen und ein Team aufstellen. Eine Aufgabenliste anlegen und dabei das Budget immer im Auge behalten.
              </p>
              <p>
                Ob technische Ausstattung, Medienkompetenz, digitale Bildungsangebote oder Verwaltungsprozesse: Entdecke von <Link href="/process/categories"><a>8 Aktionsfeldern</a></Link> das passende für Deine Idee oder lass Dich einfach inspirieren. Also: Nutze die Chance, Deine Idee einzubringen und gestalte Dein Cottbus!
              </p>
              <p className="no-flex">
                <Link href="/ideas/create"><a className="btn primary-btn">Idee einreichen</a></Link>
              </p>
            </Col>
            <Col className="col-big-breakpoint">
              <p>
                Du kannst auch ohne eigene Ideen die Projekte der anderen voranbringen. Ob finanziell, mit Expertise oder durch Sachmittel. Dein Engagement hilft anderen, Cottbus zu gestalten!
              </p>
              <p className="no-flex">
                <Link href="/partner-market"><a className="btn primary-btn">Projekte unterstützen</a></Link>
              </p>
            </Col>
          </Row>
        </>
      </section>
      <section>
        <Row className="flex">
          <Col className="text-center">
            <h2>Ideen gestalten, Wirkung spüren</h2>
          </Col>
        </Row>

        <Row>
          <Col><p className="text-center"><img src="/assets/img/cottbus/icon-ideen.svg"/><br /><b>1. Idee</b></p>
            <p className="margin-top-phases">
              Ein Ausleihsystem für Roboter? Tutorials für den ePerso? ChatGPT-Kurse für die Grundschule? Eine Freiwillige IT-Feuerwehr? Deiner Kreativität sind keine Grenzen gesetzt, wenn es darum geht, eine Idee zu formulieren. Mehr als einen kurzen Satz brauchst Du dafür zunächst nicht. Nur noch einen Account anlegen und Deine Idee wird für alle sichtbar.
            </p>
          </Col>
          <Col><p className="text-center"><img src="/assets/img/cottbus/icon-projekte.svg"/> <br /><b>2. Projekte</b></p>
            <p className="margin-top-phases">
              Mit Deinem Account kannst Du alle Funktionen vom IdeenLAB nutzen, um Deine Idee zu einem konkreten Projekt zu entwickeln. Finde Gleichgesinnte und plant gemeinsam die Umsetzung.
            </p>
          </Col>
          <Col><p className="text-center"><img src="/assets/img/cottbus/icon-wirkung.svg"/><br /><b>3. Wirkung</b></p>
            <p className="margin-top-phases">
              Sobald Ihr Euer Projekt geplant habt, seid ihr bereit, damit wirksam zu werden. Stellt ein Ausleihsystem für Roboter bereit, dreht Tutorials oder löscht IT-Feuer: Macht Euer Projekt für Cottbus greif- und spürbar!
            </p>
          </Col>
        </Row>
        <Row className="no-flex text-center">
          <Link href="/so-gehts"><a className="btn">So funktioniert&apos;s</a></Link>
        </Row>

        {/* <Row>
          <Col className="text-center"><p><b>Entdecke den Prozess von IdeenLAB. Starte dazu das Video:</b></p>
            <DSGVOContentElement
              dataTransferText="Mit dem Klicken des Buttons stimmst Du zu, dass Deine IP-Adresse sowie Daten Deines Webbrowsers zum Zwecke des Videoabrufs an die Brandenburgische Technische Universität Cottbus-Senftenberg übermittelt werden."
              previewPictureUrl="/assets/img/cottbus/video_preview.png"
            >
              {typeof window !== "undefined" &&
                <HtmlContent
                  content={`<iframe
                src="https://www.b-tu.de/media/media/embed?key=2012da1317ec058a742fcee78fe0fb3d&width=${calculateProportionateDimensionsOnBrowserDimensions(720, 405).width}&height=${calculateProportionateDimensionsOnBrowserDimensions(720, 405).height}&autoplay=false&autolightsoff=false&loop=false&chapters=false&related=false&responsive=false&t=0"
                data-src=""
                class="embedded-content"
                width="${calculateProportionateDimensionsOnBrowserDimensions(720, 405).width}"
                height="${calculateProportionateDimensionsOnBrowserDimensions(720, 405).height}"
                frameborder="0"
                scrolling="no"
                allowFullScreen
              >
              </iframe>`}
                />}
            </DSGVOContentElement>
          </Col>
        </Row> */}
      </section>

      <section className="on-white header section-padding" id="veranstaltungen">
        <Row>
          <Col className={"card-flex-layout"}>
            <h2>Veranstaltungen für Dein Projekt</h2>
            <p>
              In Workshops und Q&A-Sessions zeigen wir Dir Tipps und Tricks, damit Dein Projekt ein echter Erfolg wird. Und ganz nebenbei kannst Du Dich mit Gleichgesinnten vernetzen.
            </p>
            <div className="margin-top">
              <RSSFeedToEventsView url={BASE_URL + "/assets/cottbus/events.xml"} maxNumberOfEvents={4} hideOldEventsAfterHours={12} 
    // postprocessLoadedJson={processEventsFeed}
    targetWindow="_new"/>
            </div>
          </Col>
        </Row>
      </section>

      <section className="section-padding">
        <Row>
          <Col>
            <h2>Das sind die Macher:innen vom IdeenLAB:</h2>
            <p>Die Smart City Cottbus geht hier gemeinsam mit der Brandenburgischen Technischen Universität Cottbus-Senftenberg neue Wege, um Dich als Bürger:in aktiv in die Gestaltung der Stadt einzubeziehen.
              Das Modellprojekt &#39;Smart City&#39; umfasst insgesamt sieben Handlungsfelder der Daseinsvorsorge: Bildung, Energie, Gesundheit, Mobilität, Stadtentwicklung, Wirtschaft und Verwaltung.
              Die Plattform &#39;IdeenLAB – Gestalte Dein Cottbus!&#39; ist ein Projekt im Handlungsfeld Bildung.
            </p>
            <p>Du hast Fragen oder Anregungen? Dann schreib uns gern eine E-Mail über &#39;Kontakt&#39;. Wir, Andrea und Sophie, sind von Montag bis Freitag, 09:00 – 12:00 Uhr, für Dich erreichbar und melden uns schnellstmöglich zurück. Oder erfahre bei &#39;Über das Projekt IdeenLAB&#39; mehr über uns und das Projekt. </p>
          </Col>
          <Col>
            <div className="flex"><img width="130px" height="100%" src="/assets/img/cottbus/person1.png"/><div className="middle"><b>Dr. Claudia Börner</b><br />Projektleiterin IdeenLAB und Leiterin des Informations-, Kommunikations- und Medienzentrums (IKMZ) der BTU Cottbus-Senftenberg</div></div>
            <div className="flex margin-top"><img width="130px" height="100%" src="/assets/img/cottbus/person2.png"/><div className="middle"><b>Andrea Bölke</b><br />Community-Management und Mediendidaktische Begleitung von Bürger:innenprojekten</div></div>
            <div className="flex margin-top"><img width="130px" height="100%" src="/assets/img/cottbus/person3.png"/><div className="middle"><b>Sophie Handreka</b><br />Community- und Projektmanagement</div></div>
          </Col>
        </Row>
        <Row className="btn-flex-layout">
          <Link href="/kontakt"><a className="btn margin-top">Kontakt</a></Link>
          <Link href="/ueber"><a className="btn margin-top">Übers IdeenLAB</a></Link>
          <Link href="/so-gehts"><a className="btn margin-top">So funktionierts</a></Link>
        </Row>
      </section>


    </div>
  </BaseLayout>;
};
export default FrontPage;

    async function __Next_Translate__getStaticProps__190baf579ae__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190baf579ae__ as getStaticProps }
  